/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.app-background {
    display: flex;
    background: linear-gradient(to right, #a18cd1 0%, #fbc2eb 100%);
    min-height: 100vh;
}

::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.sidebar {
    transition: all 0.5s;
    width: 200px;
    background: #736496;
    color: white;
    overflow-y: auto;
    height: 100%;
    border-radius: 7px 0px 0px 7px;
    padding: 30px;
}

.sidebar.open {
    width: 0;
    padding: 0;
}

.section-button {
    display: flex;
    align-items: center;
    padding: 10px;
    color: white;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: background 0.5s;
    width: 100%;
    border-radius: 10px;
}

.section-button:hover {
    background: rgba(255, 255, 255, 0.1);
}

.icon {
    margin-right: 10px;
}
